import { type Static, Type } from '@sinclair/typebox';

export const CheckoutStartTypedSchema = Type.Object({
	msg: Type.String(),
	checkout: Type.Union([
		Type.Number(),
		Type.Object({
			id: Type.Number(),
			hash: Type.String(),
		}),
	]),
});

export type CheckoutStartResponse = Static<typeof CheckoutStartTypedSchema>;

const LineItem = Type.Object({
	quantity: Type.Number(),
	variantId: Type.String(),
	shippingDate: Type.Optional(Type.String()),
	customAttributes: Type.Array(
		Type.Object({
			key: Type.String(),
			value: Type.String(),
		}),
	),
});

const Variant = Type.Object({
	attributes: Type.Array(
		Type.Object({
			key: Type.String(),
			value: Type.String(),
		}),
	),
	compareAtPrice: Type.String(),
	id: Type.String(),
	imageSrc: Type.String(),
	options: Type.Array(
		Type.Object({
			name: Type.String(),
			value: Type.String(),
		}),
	),
	price: Type.String(),
	productTitle: Type.String(),
	quantity: Type.Number(),
	vendor: Type.String(),
	productId: Type.String(),
	productHandle: Type.String(),
	tags: Type.Array(Type.String()),
	pieces: Type.Optional(Type.Any()),
	weight: Type.Number(),
	quantityAvailable: Type.Number(),
	quantityAdjusted: Type.Boolean(),
	quantityAdjustment: Type.Optional(Type.Any()),
	new_price: Type.Optional(Type.Number()),
	productAttributes: Type.Optional(
		Type.Object({
			'product-type': Type.String(),
			'color-type': Type.String(),
			season: Type.String(),
			fabric: Type.String(),
			'shirt-fabrics': Type.String(),
			'trouser-fabrics': Type.String(),
			'bottom-fabrics': Type.String(),
			'dupatta-fabrics': Type.String(),
			'number-of-pieces': Type.String(),
		}),
	),
	node_l1: Type.Optional(Type.String()),
	node_l2: Type.Optional(Type.String()),
	node_l3: Type.Optional(Type.String()),
	node_l4: Type.Optional(Type.String()),
});

const Checkout = Type.Object({
	id: Type.Number(),
	hash: Type.String(),
	email: Type.Union([Type.String(), Type.Null()]),
	phone: Type.Union([Type.String(), Type.Null()]),
	shipping_address: Type.Union([Type.Object({}), Type.Null()]),
	custom_attributes: Type.Union([
		Type.Array(
			Type.Object({
				key: Type.String(),
				value: Type.String(),
			}),
		),
		Type.Null(),
	]),
	note: Type.Union([Type.String(), Type.Null()]),
	storefront_gid: Type.Union([Type.String(), Type.Null()]),
	storefront_web_url: Type.Union([Type.String(), Type.Null()]),
	completed: Type.Boolean(),
	line_items: Type.Array(LineItem),
	phone_prefix: Type.Union([Type.String(), Type.Null()]),
	logan_id: Type.Union([Type.String(), Type.Null()]),
	createdAt: Type.String(),
	updatedAt: Type.String(),
});

export const CheckoutTypedSchema = Type.Object({
	checkout: Checkout,
	lineItems: Type.Object({
		variantsLis: Type.Optional(Type.Array(Variant)),
		variantsWithZeroQuantity: Type.Optional(Type.Union([Type.Array(Variant)])),
	}),
});

export type CheckoutResponse = Static<typeof CheckoutTypedSchema>;

export type VariantType = Static<typeof Variant>;
export type VariantLis = VariantType[];

const accountDetailsSchema = Type.Object({
	Title: Type.String(),
	'Bank Name': Type.Optional(Type.String()),
	'Account Title': Type.Optional(Type.String()),
	'Account Number': Type.Optional(Type.String()),
	IBAN: Type.Optional(Type.String()),
	'Swift Code': Type.Optional(Type.String()),
	'Branch Address': Type.Optional(Type.String()),
});

export const paymentMethod = Type.Object({
	name: Type.String(),
	description_type: Type.String(),
	description: Type.Union([Type.Null(), Type.Array(accountDetailsSchema)]),
});

export const paymentMethodsSchema = Type.Array(paymentMethod);

export type PaymentMethods = Static<typeof paymentMethodsSchema>;
export type AccountDetails = Static<typeof accountDetailsSchema>;
export const placeOrderSchema = Type.Object({
	msg: Type.String(),
	code: Type.Number(),
	data: Type.Object({
		totalTax: Type.Union([Type.Number(), Type.Null()]),
		shipping: Type.Union([Type.Number(), Type.Null()]),
		discount: Type.Number(),
		subTotal: Type.Number(),
		date: Type.String(),
		paymentMethod: Type.String(),
		shopifyId: Type.Union([Type.Number(), Type.String()]),
		requestId: Type.Optional(Type.String()),
		promotionAmount: Type.Optional(Type.Number()),
		orderNumber: Type.Optional(Type.String()),
		redisKey: Type.Optional(Type.String()),
	}),
});

export type PlaceOrderData = Static<typeof placeOrderSchema>;

export const ShippingRatesSchema = Type.Object({
	code: Type.Number(),
	data: Type.Object({
		id: Type.Number(),
		name: Type.String(),
		price: Type.String(),
		shipping_zone_id: Type.Number(),
		min_order_subtotal: Type.Optional(Type.Union([Type.String(), Type.Null()])),
		max_order_subtotal: Type.Optional(Type.Union([Type.String(), Type.Null()])),
		weight_high: Type.Optional(Type.Number()),
		weight_low: Type.Optional(Type.Number()),
	}),
});

export type ShippingRates = Static<typeof ShippingRatesSchema>;

export const shopifyCheckoutSchema = Type.Object({
	msg: Type.String(),
	code: Type.Number(),
	data: Type.Object({
		checkout: Type.Object({
			availableShippingRates: Type.Object({
				shippingRates: Type.Array(
					Type.Object({
						handle: Type.String(),
						title: Type.String(),
						priceV2: Type.Object({
							amount: Type.String(),
							currencyCode: Type.String(),
						}),
						price: Type.Object({
							amount: Type.String(),
							currencyCode: Type.String(),
						}),
					}),
				),
				ready: Type.Boolean(),
			}),
			completedAt: Type.Union([Type.String(), Type.Null()]),
			createdAt: Type.String(),
			id: Type.String(),
			currencyCode: Type.String(),
			requiresShipping: Type.Boolean(),
			totalDuties: Type.Union([Type.String(), Type.Null()]),
			totalPriceV2: Type.Object({
				amount: Type.String(),
				currencyCode: Type.String(),
			}),
			totalTaxV2: Type.Object({
				amount: Type.String(),
				currencyCode: Type.String(),
			}),
			subtotalPriceV2: Type.Object({
				amount: Type.String(),
				currencyCode: Type.String(),
			}),
			webUrl: Type.String(),
			updatedAt: Type.String(),
		}),
		checkoutUserErrors: Type.Array(Type.Any()),
	}),
});

export type ShopfiyCheckoutData = Static<typeof shopifyCheckoutSchema>;

export const TaxRatesSchema = Type.Object({
	code: Type.Number(),
	msg: Type.Optional(Type.String()),
	data: Type.Optional(
		Type.Object({
			estimatedCombinedRate: Type.Number(),
			state: Type.String(),
		}),
	),
});

export type TaxRates = Static<typeof TaxRatesSchema>;

export const XpayCheckoutSchema = Type.Object({
	encryptionKey: Type.String(),
	pi_client_secret: Type.String(),
	customer: Type.Object({
		amount: Type.Number(),
		name: Type.String(),
		email: Type.String(),
		phone: Type.String(),
		address1: Type.String(),
		address2: Type.Optional(Type.String()),
		city: Type.String(),
		country: Type.String(),
		firstName: Type.Optional(Type.String()),
		lastName: Type.Optional(Type.String()),
		province: Type.Optional(Type.String()),
		zip: Type.String(),
		shipping_method: Type.Optional(Type.String()),
	}),
});

export type XpayCheckoutResponse = Static<typeof XpayCheckoutSchema>;

export const StripeCheckoutSchema = Type.Object({
	client_secret: Type.String(),
});

export type StripeCheckoutResponse = Static<typeof StripeCheckoutSchema>;

export const SignupRewardsSchema = Type.Object({
	amount: Type.Number(),
});

export type SignupRewardsResponse = Static<typeof SignupRewardsSchema>;

export const RewardsIndexSchema = Type.Object({
	balance: Type.Number(),
	expiring_rewards: Type.Object({
		amount: Type.Optional(Type.Number()),
		date: Type.Optional(Type.Union([Type.String(), Type.Null()])),
	}),
});

export type RewardsIndexResponse = Static<typeof RewardsIndexSchema>;

export const RewardsHistorySchema = Type.Array(
	Type.Optional(
		Type.Object({
			reward_type: Type.String(),
			amount: Type.Number(),
			status: Type.String(),
			date: Type.String(),
			order_name: Type.Union([Type.String(), Type.Null()]),
		}),
	),
);

export type RewardsHistoryResponse = Static<typeof RewardsHistorySchema>;

export const RewardsRatesSchema = Type.Record(
	Type.String(), // This defines the dynamic key (currency codes as strings)
	Type.Object({
		native_to_pkr: Type.Number(),
		coins_to_native: Type.Number(),
	}),
);

export type RewardsRatesResponse = Static<typeof RewardsRatesSchema>;

export interface DynamicField {
	fieldLabel: string;
	fieldName: string;
	type: string;
	value: any;
}
